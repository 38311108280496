import { useEffect, useState } from "react";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { BsPlus } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { fetchData } from "../../GlobalFunctions";

type InputField = {
  label: string;
  type: string;
  value?: string[];
  validation: { required?: boolean };
  listId?: string;
};

type FormValues = {
  system_form: {
    title: string;
    fields: InputField[];
    prompt: string;
  };
  custom_form: {
    title: string;
    fields: InputField[];
    prompt: string;
  };
  form_name: string;
  description: string;
  id: string;
  user_id: number;
  roleDescription?: string;
};

interface RoleItem {
  roleId: string;
  roleName: string;
  description: string;
}

interface StyleItem {
  id: string;
  label: string;
  type: string;
  value: string[];
  required: boolean;
}

function FormBuilder({ clientId }: any) {
  const randomId = Math.random().toString(36).substring(7);
  const [loading, setLoading] = useState(false);
  const [dataLists, setDataLists] = useState<StyleItem[]>([]);
  const [selectedDataLists, setSelectedDataLists] = useState<StyleItem[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dataListOpen, setDataListOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [roleList, setRoleList] = useState<RoleItem[]>([]);
  const [selectedRole, setSelectedRole] = useState<RoleItem | null>(null);

  const handleDataListClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDataListOpen(!dataListOpen);
    setMenuOpen(false);
  };

  const handleRoletClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setRoleOpen(!roleOpen);
    setMenuOpen(false);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const { register, control, handleSubmit, watch, reset, setValue } =
    useForm<FormValues>({
      defaultValues: {
        user_id: clientId,
        id: randomId,
        form_name: "",
        description: "",
        system_form: {
          title: "",
          fields: [
            {
              label: "",
              type: "text",
              validation: {
                required: false,
              },
            },
          ],
          prompt: "",
        },
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "system_form.fields",
  });

  const fieldTypes = watch("system_form.fields");

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    let enhancedFields = [...data.system_form.fields];
    selectedDataLists.forEach((list) => {
      enhancedFields.push({
        label: list.label,
        type: list.type,
        listId: list.id,
        value: list.value,
        validation: {
          required: list.required,
        },
      });
    });
    const enhancedData = {
      ...data,
      system_form: {
        ...data.system_form,
        fields: enhancedFields,
      },
      custom_form: {
        ...data.system_form,
        fields: enhancedFields,
      },
    };
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_FORM_BUILDER}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(enhancedData),
        }
      );
      if (response.ok) {
        toast.success("Form data sent successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        setSelectedDataLists([]);
        setSelectedRole(null);
        setRoleOpen(false);
        setMenuOpen(false);
        setDataListOpen(false);
        reset();
      } else {
        toast.error("Failed to send form data.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    } finally {
      setSelectedDataLists([]);
      setSelectedRole(null);
      setLoading(false);
      setRoleOpen(false);
      setDataListOpen(false);
      setMenuOpen(false);
      reset();
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_DATA_LIST}${clientId}`,
      setDataLists
    );
  }, []);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ROLES}${clientId}`,
      setRoleList
    );
  }, []);

  const renderInput = (field: InputField, index: number) => {
    switch (field.type) {
      case "list":
        let valueArray: string[] = Array.isArray(field.value)
          ? field.value
          : [];
        return (
          <select
            {...(register(`system_form.fields.${index}` as const),
            {
              required: field.validation.required,
            })}
            className="border border-gray-300 p-2 rounded-md outline-none"
            value={valueArray}
            onChange={(e) => {
              const selectedOptions = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              field.value = selectedOptions;
            }}
            multiple
          >
            {valueArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type="text"
            {...(register(`system_form.fields.${index}` as const),
            {
              required: field.validation.required,
            })}
            className="border border-gray-300 p-2 rounded-md outline-none"
          />
        );
    }
  };

  const handleRoleSelection = (role: RoleItem) => {
    setSelectedRole(role);
    setValue("roleDescription", role.description);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-center gap-4"
    >
      <section className=" flex flex-col gap-4 items-center w-full">
        <Helmet>
          <title>boxMind Form builder</title>
          <meta
            name="description"
            content="This innovative tool ensures that every interaction with Generative AI is precise and efficient, minimizing the variability in responses due to unstructured inputs."
          />
        </Helmet>
        <div className=" flex flex-col gap-2 border rounded-lg w-full md:w-[700px] bg-white">
          <div className=" bg-[#6347ca] p-2 rounded-t-lg" />
          <div className="flex flex-col gap-2 p-4">
            <input
              {...register("form_name")}
              placeholder="Enter AI Form Name"
              required
              className="p-2 outline-none text-[30px]"
            />
            <input
              {...register("description")}
              required
              placeholder="Enter AI Form description"
              className="p-2 outline-none"
            />
          </div>
        </div>
        <div className=" flex flex-col md:flex-row gap-4 w-full md:w-[800px] justify-center">
          <div className=" flex flex-col md:flex-row rounded-lg w-full h-full">
            <div className=" grid grid-cols-1 gap-3 w-full md:w-[680px] md:ml-[50px]">
              {fields.map((field, index) => (
                <div className=" flex flex-row">
                  <div className="bg-[#6347ca] px-1 rounded-l-lg" />
                  <div
                    key={field.id}
                    className="flex flex-col p-4 bg-white rounded-lg w-full"
                  >
                    <div className="flex flex-row max-sm:flex-col gap-2 items-center">
                      <input
                        {...register(
                          `system_form.fields.${index}.label` as const
                        )}
                        placeholder="Field Name"
                        className="bg-gray-100 p-2 border-b border-[#20144c] outline-none w-full md:w-[200px]"
                      />
                      <select
                        {...register(
                          `system_form.fields.${index}.type` as const
                        )}
                        className="border border-gray-300 p-2 rounded-md outline-none cursor-pointer max-sm:w-full"
                      >
                        <option value="text">Text</option>
                        <option value="list">List</option>
                      </select>
                      {["list"].includes(fieldTypes[index]?.type) && (
                        <input
                          {...register(
                            `system_form.fields.${index}.value` as const
                          )}
                          placeholder="Enter value comma separated"
                          className="border border-gray-300 p-2 rounded-md outline-none"
                          onChange={(e) => {
                            const listValues = e.target.value
                              .split(",")
                              .map((item) => item.trim());
                            setValue(
                              `system_form.fields.${index}.value`,
                              listValues,
                              { shouldValidate: true }
                            );
                          }}
                        />
                      )}
                    </div>
                    <div className=" flex flex-row items-center gap-2 justify-end border-t mt-3 pt-2">
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className=" bg-red-500 text-white p-2 rounded-md outline-none"
                      >
                        <RiDeleteBin6Line />
                      </button>
                      <label className=" cursor-pointer flex flex-row gap-2">
                        Required:
                        <input
                          type="checkbox"
                          {...register(
                            `system_form.fields.${index}.validation.required` as const
                          )}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              {selectedDataLists.length > 0 && (
                <div className=" grid grid-cols-1 gap-3 w-full">
                  {selectedDataLists.map((selectedDataList, index) => (
                    <div className=" flex flex-row">
                      <div className="bg-[#6347ca] px-1 rounded-l-lg" />
                      <div
                        key={index}
                        className="flex flex-col p-4 bg-white rounded-lg w-full"
                      >
                        <div className=" flex gap-2 items-center">
                          <h4 className="bg-gray-100 p-2 border-b border-[#20144c] outline-none w-[200px]">
                            {selectedDataList.label}
                          </h4>
                          {/* <div className="border border-gray-300 p-2 rounded-md outline-none flex flex-row overflow-x-auto custom-scrollbar max-w-[350px]">
                            {selectedDataList.value.map((value, valueIndex) => (
                              <p key={valueIndex}>
                                {value}
                                {valueIndex !==
                                  selectedDataList.value.length - 1 && ","}
                              </p>
                            ))}
                          </div> */}
                        </div>
                        <div className=" flex flex-row items-center gap-2 justify-end border-t mt-3 pt-2">
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedDataLists((prevLists) =>
                                prevLists.filter(
                                  (list) => list.id !== selectedDataList.id
                                )
                              );
                            }}
                            className=" bg-red-500 text-white p-2 rounded-md outline-none"
                          >
                            <RiDeleteBin6Line />
                          </button>
                          <label className="cursor-pointer flex flex-row gap-2">
                            Required:
                            <input
                              type="checkbox"
                              checked={selectedDataList.required}
                              onChange={(e) => {
                                const updatedLists = selectedDataLists.map(
                                  (list) =>
                                    list.id === selectedDataList.id
                                      ? { ...list, required: e.target.checked }
                                      : list
                                );
                                setSelectedDataLists(updatedLists);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className=" relative">
            <button
              type="button"
              onClick={handleMenuClick}
              className={`bg-[#6347ca] text-white hover:bg-[#20144c] duration-300 rounded-full text-[35px] ${
                menuOpen ? " rotate-90" : ""
              }`}
            >
              <BsPlus />
            </button>
            {menuOpen && (
              <div className=" bg-gray-400 p-4 rounded-lg flex flex-col gap-2 items-center absolute top-0 left-10 w-[150px]">
                <button
                  type="button"
                  onClick={() => {
                    append({
                      label: "",
                      type: "text",
                      validation: { required: false },
                    });
                    setMenuOpen(false);
                  }}
                  className=" text-white hover:text-[#20144c] duration-300"
                >
                  Add New Field
                </button>
                <button
                  onClick={handleDataListClick}
                  className=" text-white hover:text-[#20144c] duration-300"
                >
                  Add Data List
                </button>
                <button
                  onClick={handleRoletClick}
                  className=" text-white hover:text-[#20144c] duration-300"
                >
                  Add Role
                </button>
              </div>
            )}
          </div>
        </div>
        <div className=" w-full md:w-[700px] flex flex-col gap-3">
          {dataListOpen && (
            <select
              onChange={(e) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                setSelectedDataLists((prevSelected) => {
                  const updatedSelected = [...prevSelected];
                  selectedOptions.forEach((option) => {
                    const alreadySelected = updatedSelected.some(
                      (item) => item.id === option
                    );
                    if (!alreadySelected) {
                      const selectedList = dataLists.find(
                        (list) => list.id === option
                      );
                      if (selectedList) {
                        updatedSelected.push(selectedList);
                      }
                    }
                  });
                  return updatedSelected;
                });
                e.target.value = "";
                setDataListOpen(false);
              }}
              className="border border-gray-300 p-2 rounded-md outline-none w-full"
            >
              <option value="">Select a List</option>
              {dataLists.map((list) => (
                <option key={list.id} value={list.id}>
                  {list.label}
                </option>
              ))}
            </select>
          )}
          {roleOpen && (
            <select
              onChange={(e) => {
                const selectedRoleId = e.target.value;
                const selectedRoleItem = roleList.find(
                  (role) => role.roleId === selectedRoleId
                );
                if (selectedRoleItem) {
                  handleRoleSelection(selectedRoleItem);
                }
              }}
              className="border border-gray-300 p-2 rounded-md outline-none w-full"
            >
              <option value="">Select a Role</option>
              {roleList.map((role) => (
                <option key={role.roleId} value={role.roleId}>
                  {role.roleName}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className=" flex flex-col gap-2 w-full md:w-[700px]">
          <label htmlFor="prompt" className=" text-lg font-bold ">
            Prompt
          </label>
          <textarea
            {...register("system_form.prompt")}
            required
            placeholder="Prompt example :
(supposing there are 3 fields: text field , the first language and the second language)
Translate the text <text> from the first language <lang1> to the second language <lang2>"
            className="border border-gray-300 p-2 rounded-md outline-none h-[100px]"
          />
        </div>
      </section>
      <div>
        <button
          type="submit"
          disabled={loading}
          className="bg-[#6347ca] text-white hover:bg-[#20144c] duration-300 px-4 py-2 rounded-md outline-none mb-3"
        >
          {loading ? "Sending..." : "Submit Form"}
        </button>
      </div>
    </form>
  );
}

export default FormBuilder;
