import Image from "../../Assets/Efficiency.png";

const Efficiency = () => {
  return (
    <section className=" flex justify-center items-center w-full bg-[#dceffd]">
      <div className=" flex flex-col-reverse md:flex-row items-center md:gap-[30px]">
        <div className=" max-lg:flex max-lg:justify-center px-4">
          <img
            src={Image}
            alt="pic"
            loading="lazy"
            className="rounded-lg object-cover object-top w-[450px] h-[400px]"
          />
        </div>
        <div className=" flex flex-col gap-2 max-lg:px-4 md:w-[496px] max-sm:text-center">
          <h1 className="text-[25px] md:text-[30px] text-[#1b374e] font-bold">
            Efficiency
          </h1>
          <p className=" whitespace-break-spaces md:text-[20px] text-[#1b374e]">
            Our platform maximizes efficiency in prompt creation. With
            streamlined processes and intuitive interfaces, users can generate
            custom prompts quickly and effortlessly. By eliminating unnecessary
            steps and complexities, we empower users to make the most out of
            their time, ensuring that every interaction with our platform is
            efficient and productive.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Efficiency;
