import Image from "../../Assets/Standa.jpg";

const StandStandardization = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4 ">
      <div className=" flex flex-col-reverse md:flex-row-reverse items-center md:gap-[30px]">
        <div className=" max-lg:flex max-lg:justify-center px-4">
          <img
            src={Image}
            alt="pic"
            loading="lazy"
            className="rounded-lg object-cover md:w-[450px]"
          />
        </div>
        <div className=" flex flex-col gap-2 max-lg:px-4 md:w-[446px] max-sm:text-center">
          <h1 className="text-[25px] md:text-[30px] text-[#1b374e] font-bold ">
            Standardization
          </h1>
          <p className=" whitespace-break-spaces md:text-[20px] text-[#1b374e]">
            Creating prompts is straightforward with our standardized approach.
            Our platform ensures consistency and compatibility across different
            applications, making it easy for users to generate prompts without
            needing to worry about complex engineering tasks.
          </p>
        </div>
      </div>
    </section>
  );
};

export default StandStandardization;
