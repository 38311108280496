import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import NotFoundPage from "./components/404";
import { useState } from "react";
import Dashboard from "./components/Dashboard/Dashboard";
import PromptEng from "./components/PromptEng";
import UsePrompt from "./components/PromptEng/UsePrompt";
import FormBuilder from "./components/FormBuilder";
import DataList from "./components/DataList";
import Roles from "./components/Role";
import Permissions from "./components/Permissions";
import HomeScreen from "./components/HomeScreen";

function App() {
  const [clientId] = useState(() => {
    const storedClientId = localStorage.getItem("clientId_AI");
    return storedClientId
      ? parseInt(storedClientId, 10)
      : Math.floor(Math.random() * 1e9);
  });

  localStorage.setItem("clientId_AI", clientId.toString());

  return (
    <section className=" overflow-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/forms" element={<Dashboard />}>
            <Route path="" element={<HomeScreen />} />
            <Route
              path="/forms/ai-forms"
              element={<PromptEng clientId={clientId} />}
            />
            <Route
              path="/forms/use-prompt/:ai_form_id"
              element={<UsePrompt clientId={clientId} />}
            />
            <Route
              path="/forms/ai-form-builder"
              element={<FormBuilder clientId={clientId} />}
            />
            <Route
              path="/forms/data-list"
              element={<DataList clientId={clientId} />}
            />
            <Route path="/forms/role" element={<Roles clientId={clientId} />} />
            <Route
              path="/forms/permissions"
              element={<Permissions clientId={clientId} />}
            />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </section>
  );
}

export default App;
