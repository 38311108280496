import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoCreateOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { fetchData } from "../../GlobalFunctions";
import { Helmet } from "react-helmet";

const Roles = ({ clientId }: any) => {
  interface RoleItem {
    roleId: string;
    roleName: string;
    description: string;
  }

  const [roleList, setRoleList] = useState<RoleItem[]>([]);
  const [showCreator, setShowCreator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const randomId = Math.random().toString(36).substring(7);
  const [roleName, setRoleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleRoleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const requestData = {
        user_id: clientId,
        role_id: randomId,
        role_name: roleName,
        description: description,
      };
      await axios.post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_CREATE_ROLE}`,
        JSON.stringify(requestData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Role created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      setRoleName("");
      setDescription("");
    } catch (error) {
      toast.error("Error creating Role.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.error(error);
    } finally {
      setLoading(false);
      setRoleName("");
      setDescription("");
      setShowCreator(false);
      setRefresh((prevRefresh: any) => prevRefresh + 1);
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ROLES}${clientId}`,
      setRoleList
    );
  }, [refresh]);

  const deleteRole = async (id: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_DELETE_ROLE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: clientId,
            role_id: id,
          }),
        }
      );
      if (response.ok) {
        setRefresh((prevRefresh: any) => prevRefresh + 1);
        toast.success("Role deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        toast.error("Failed to delete Role.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error deleting Role:", error);
    }
  };

  return (
    <section className={`flex flex-col justify-center items-center gap-4`}>
      <Helmet>
        <title>boxMind Role</title>
        <meta
          name="description"
          content="The Role Selector component allows users to define and select their
          roles within a conversation or context. It enables individuals to shape
          discussions, access relevant tools and data, and collaborate effectively
          based on their designated roles."
        />
      </Helmet>
      <h1 className="text-2xl font-bold ">Role</h1>
      <p className=" text-center md:w-[600px]">
        The Role Selector component allows users to define and select their
        roles within a conversation or context. It enables individuals to shape
        discussions, access relevant tools and data, and collaborate effectively
        based on their designated roles.
      </p>
      <div className=" flex flex-col md:flex-row justify-center w-full gap-6">
        <table className="table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Role Name</th>
              <th className="px-4 py-2 border">Role Description</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {roleList.map((item) => (
              <tr key={item?.roleId}>
                <td className="border px-4 py-2">{item?.roleName}</td>
                <td className="border px-4 py-2">{item.description}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => deleteRole(item.roleId)}
                    className="bg-red-500 text-white p-2 rounded-md outline-none"
                  >
                    <RiDeleteBin6Line />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showCreator && (
          <button
            className=" absolute right-5 top-[13%]"
            onClick={() => setShowCreator(!showCreator)}
          >
            <IoCreateOutline title="Create new data list" size={40} />
          </button>
        )}
        {showCreator && (
          <form
            onSubmit={handleSubmit}
            className="absolute right-0 top-0 h-full bg-white shadow-lg p-4 transition-transform transform translate-x-0"
          >
            <section className=" flex flex-col gap-4 w-[300px]">
              <div className=" flex flex-row justify-between w-full">
                <p className="text-xl">Create Role</p>
                <button
                  onClick={() => setShowCreator(false)}
                  className="text-2xl"
                >
                  <IoMdClose title="close" size={30} />
                </button>
              </div>
              <div className=" flex flex-col gap-2">
                <label
                  htmlFor="roleName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Role Name
                </label>
                <input
                  type="text"
                  id="roleName"
                  value={roleName}
                  onChange={handleRoleNameChange}
                  placeholder="Role Name"
                  className=" block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm outline-none p-2"
                />
              </div>
              <div className=" flex flex-col gap-2">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  rows={4}
                  placeholder="Role Description"
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm outline-none p-2"
                />
              </div>
            </section>
            <div>
              <button
                type="submit"
                disabled={loading}
                className="bg-[#6347ca] text-white hover:bg-[#20144c] duration-300 px-4 py-2 rounded-md outline-none my-4"
              >
                {loading ? "Sending..." : "Create Role"}
              </button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default Roles;
