import { Link, useLocation } from "react-router-dom";
import { MdEngineering } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { HiOutlineClipboardList } from "react-icons/hi";
import Boxmind from "../../Assets/boxMind_wide_white.png";
import { BsPerson } from "react-icons/bs";

function SideMenu({ changeSideActive }: any) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <menu className="text-[white] font-semibold text-[16px] h-screen select-none">
      <div className="h-[60px]">
        <div className=" flex items-center justify-center">
          <Link to={"/"}>
            <img
              src={Boxmind}
              alt="Boxmind"
              width={150}
              className="pt-2 -ml-5"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
      <div
        className="text-[#a9aaac] font-semibold overflow-y-scroll custom-scrollbar h-screen  "
        style={{ maxHeight: "calc(100vh - 140px)" }}
      >
        <div className="overflow-y-scroll custom-scrollbar ">
          <div className="py-4 flex flex-col gap-2">
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/forms/ai-forms"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/forms/ai-forms"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/forms/ai-forms"}
                >
                  <MdEngineering title="AI Forms" size={25} />
                  AI Forms
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/forms/ai-form-builder"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/forms/ai-form-builder"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/forms/ai-form-builder"}
                >
                  <FaWpforms title="AI Forms Builder" size={25} />
                  AI Forms Builder
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/forms/data-list"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/forms/data-list"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/forms/data-list"}
                >
                  <HiOutlineClipboardList title="Data List" size={25} />
                  Data List
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/forms/role"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/forms/role"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/forms/role"}
                >
                  <BsPerson title="Role" size={25} />
                  Role
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname === "/forms/permissions"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname === "/forms/permissions"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/forms/permissions"}
                >
                  <HiOutlineClipboardList title="permissions" size={25} />
                  Permissions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </menu>
  );
}

export default SideMenu;
