const Section1 = () => {
  return (
    <section className="px-4 pb-8 w-full ">
      <div className=" flex flex-col items-center justify-center gap-4">
        <h1 className="text-[25px] md:text-[30px] text-[#1b374e] font-bold text-center ">
          Optimizing Workplace Interactions for Precision and Efficiency
        </h1>
        <p className=" whitespace-break-spaces w-[996px] text-center md:text-[20px] text-[#1b374e]">
          AI Forms revolutionizes workplace interactions by standardizing prompt
          engineering across teams. It is like having Google Forms but for
          Generative AI. This innovative tool ensures that every interaction
          with Generative AI is precise and efficient, minimizing the
          variability in responses due to unstructured inputs. By utilizing a
          validated form builder, AI Forms guides users through crafting optimal
          prompts, guaranteeing that all necessary information is gathered
          before submission. This process not only enhances the quality of data
          fed into the AI but also streamlines communication, leading to more
          accurate and useful AI responses. Elevate your team's productivity and
          decision-making capabilities with AI Forms, where intelligent design
          meets user-friendly technology
        </p>
      </div>
    </section>
  );
};

export default Section1;
