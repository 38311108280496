import Image from "../../Assets/Prompt.jpeg";

const Prompt = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4 bg-[#ffeede]">
      <div className=" flex flex-col-reverse md:flex-row  items-center md:gap-[30px]">
        <div className=" max-lg:flex max-lg:justify-center px-4">
          <img
            src={Image}
            alt="pic"
            loading="lazy"
            className="rounded-lg object-cover md:w-[450px]"
          />
        </div>
        <div className=" flex flex-col gap-2 max-lg:px-4 md:w-[496px] max-sm:text-center">
          <h1 className="text-[25px] md:text-[30px] text-[#1b374e] font-bold ">
            No need for prompt engineering
          </h1>
          <p className=" whitespace-break-spaces md:text-[20px] text-[#1b374e]">
            Forget about the complexities of prompt engineering. Our
            user-friendly platform empowers individuals of all backgrounds to
            create custom prompts without specialized skills. With intuitive
            input forms guiding the way, users can harness the power of language
            models effortlessly, democratizing access to advanced AI
            capabilities.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Prompt;
