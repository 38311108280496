import { useLocation } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { IoIosMenu } from "react-icons/io";

function NavBar({ changeSideActive }: any) {
  const location = useLocation();

  return (
    <nav className=" flex flex-row justify-between items-center bg-[#20144C] border-b border-[#C5D4EB] p-4 h-[70px]">
      <div className=" uppercase text-[white] font-semibold text-[15px]">
        {location.pathname
          .slice(7)
          .replaceAll("-", " ")
          .substring(
            0,
            location.pathname.slice(1).indexOf("/forms") > 0
              ? location.pathname.slice(1).indexOf("/")
              : location.pathname.length
          )}
      </div>
      <div className="flex flex-row gap-10 max-sm:gap-2 items-center">
        <div className="flex flex-row gap-2 items-center text-[white]">
          <div>
            <BiUserCircle className=" text-[35px] max-lg:text-[25px]" />
          </div>

          <div className=" lg:hidden mt-1">
            <button onClick={() => changeSideActive()}>
              <IoIosMenu size={25} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
