import boxMind from "../../Assets/boxMind_wide.png";
const Footer = () => {
  return (
    <section className=" p-4 flex flex-row justify-center items-center">
      <div className=" flex flex-row items-center gap-2">
        <p>Powered by</p>
        <a href="https://boxmind.ai/" target="_blank" rel="noopener noreferrer">
          <img src={boxMind} alt="boxMind" className="h-[35px] object-cover" />
        </a>
      </div>
    </section>
  );
};

export default Footer;
