import { useEffect, useState } from "react";
import { ImSpinner } from "react-icons/im";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { fetchData } from "../../GlobalFunctions";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { toast } from "react-toastify";
import { BiEdit } from "react-icons/bi";
import EditPrompt from "./EditPrompt";

let currentIdDatabase = 0;

function PromptEng({ clientId }: any) {
  interface PromptData {
    ai_form_id: string;
    available_to_all_users: string;
    custom_form: {
      fields: [any];
      prompt: string;
      title: string;
      key_submit: string;
    };
    description: string;
    form_name: string;
    picture: string;
    custom_created: number;
    system_form: { fields: [any] };
  }

  const [promptData, setPromptData] = useState<PromptData[] | null>(null);
  const [refresh, setRefresh] = useState(0);
  const [PromptEditOpen, setPromptEditOpen] = useState(false);

  const openEditPrompt = (id: number) => {
    setPromptEditOpen(true);
    currentIdDatabase = id;
  };

  const closeEditPrompt = () => {
    setPromptEditOpen(false);
  };

  const handlePromptEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEditPrompt();
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_AI_FORMS_BY_USER}${clientId}`,
      setPromptData
    );
  }, [refresh]);

  const handleDelete = async (id: string) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API}${id}/${clientId}${process.env.REACT_APP_DELETE_PROMPT}`,
        {
          id,
        }
      );
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      toast.success("Form deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("Error deleting Form!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.error("Error deleting form:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>boxMind AI Forms</title>
        <meta
          name="description"
          content="AI Forms guides users through crafting optimal prompts, guaranteeing that all necessary information is gathered before submission."
        />
      </Helmet>
      {promptData && promptData.length > 0 ? (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {promptData?.map((data, index) => (
            <div
              key={index}
              className=" relative flex flex-col m-4 hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]"
            >
              {data.custom_created === 1 && (
                <>
                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDelete(data.ai_form_id);
                    }}
                    className="absolute right-2 top-2 bg-red-600 hover:bg-red-500 text-white p-2 rounded duration-300 z-20"
                  >
                    <RiDeleteBin6Line />
                  </button>
                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      openEditPrompt(index);
                    }}
                    className="absolute left-2 top-2 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300 z-20"
                  >
                    <BiEdit />
                  </button>
                </>
              )}
              <Link to={`/forms/use-prompt/${data.ai_form_id}`} target="_blank">
                <div className=" ">
                  <div className="flex justify-center">
                    <AsyncImage
                      className=" rounded-t-lg max-h-[300px] content-center "
                      src={`data:image/jpg;base64,${data?.picture}`}
                      alt={data?.form_name}
                      loading="lazy"
                      Transition={Blur}
                      style={{ width: 500, height: 300 }}
                    />
                  </div>
                </div>
                <div className=" bg-[#4D30B5] pt-2 pb-1 rounded-b-lg">
                  <p className=" text-[20px] text-white mb-2 text-center">
                    {data?.form_name}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
      {promptData
        ? PromptEditOpen && (
            <EditPrompt
              handlePromptEditClick={handlePromptEditClick}
              closeEditPrompt={closeEditPrompt}
              form_prompt={promptData[currentIdDatabase].custom_form.prompt}
              form_id={promptData[currentIdDatabase].ai_form_id}
              setRefresh={setRefresh}
              clientId={clientId}
            />
          )
        : null}
    </div>
  );
}

export default PromptEng;
