import Image from "../../Assets/Validation.jpg";

const Validation = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <div className=" flex flex-col-reverse md:flex-row-reverse items-center md:gap-[30px]">
        <div className=" max-lg:flex max-lg:justify-center px-4">
          <img
            src={Image}
            alt="pic"
            loading="lazy"
            className="rounded-lg object-cover md:w-[450px] md:h-[400px]"
          />
        </div>
        <div className=" flex flex-col gap-2 max-lg:px-4 md:w-[496px] max-sm:text-center">
          <h1 className="text-[25px] md:text-[30px] text-[#1b374e] font-bold">
            Validation
          </h1>
          <p className=" whitespace-break-spaces md:text-[20px] text-[#1b374e]">
            Quality assurance is at the core of our project. We rigorously
            validate all prompts to guarantee coherence, relevance, and
            linguistic integrity. Through automated checks and human evaluation,
            we ensure that every generated prompt meets predefined standards for
            usability and performance, providing users with confidence in their
            outputs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Validation;
