import "react-slideshow-image/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form1 from "../../Assets/Forms/Form1.jpeg";
import Form2 from "../../Assets/Forms/Form2.jpeg";
import Form3 from "../../Assets/Forms/Form3.jpeg";
import Form4 from "../../Assets/Forms/Form4.jpeg";
import Form5 from "../../Assets/Forms/Form5.jpeg";
import Form6 from "../../Assets/Forms/Form6.jpeg";

const Banner = () => {
  const slideImages = [
    {
      src: Form1,
      caption: "Content Creator",
    },
    {
      src: Form2,
      caption: "Paraphraser",
    },
    {
      src: Form3,
      caption: "Posts Generator",
    },
    {
      src: Form4,
      caption: "Email Generator",
    },
    {
      src: Form5,
      caption: "Grammar Fixer",
    },
    {
      src: Form6,
      caption: "Text Translator",
    },
  ];

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <section className="banner bg-no-repeat overflow-hidden bg-cover">
      <div className=" flex flex-col items-center gap-4 justify-center p-4 select-none">
        <p className="text-[25px] md:text-[30px] text-[#1b374e] font-bold text-center pt-24">
          AI Forms: The new Form builder for AI
        </p>
      </div>
      <div className="container mx-auto px-4 md:w-[997px] ">
        <Slider {...settings}>
          {slideImages.map((slide, index) => (
            <div className="relative cursor-grab" key={index}>
              <div className="absolute top-[5%] text-[#1b374e] left-[35%] md:left-[40%] font-bold z-10 text-[20px] md:text-[30px] max-sm:hidden">
                {slide.caption}
              </div>
              <img
                loading="lazy"
                src={slide.src}
                alt={slide.caption}
                className="w-full h-auto rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className=" flex justify-center py-4">
        <div className=" flex flex-row items-center gap-3 lg:pb-10">
          <a
            href="/forms/ai-forms"
            className=" bg-[#F47829] text-white px-3 py-2 rounded-md hover:bg-[#C94E21] duration-300 text-[25px]"
          >
            Start Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default Banner;
