import { useEffect, useState } from "react";
import { fetchData } from "../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";

const Permissions = ({ clientId }: any) => {
  const [aIData, setAIData] = useState<any[]>([]);
  const [checkedForms, setCheckedForms] = useState<string[]>([]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_PERMISSIONS}${clientId}`,
      setAIData
    );
  }, []);

  return (
    <form className="w-full">
      <div className="p-4 flex flex-col gap-4">
        <div>
          <label className="pb-2 text-[20px]">Forms:</label>
          <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2">
            <ul
              className="px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
              aria-labelledby="dropdownSearchButton"
            >
              {aIData.length ? (
                aIData.map((form, index) => (
                  <li key={index}>
                    <div className="flex items-center p-2 rounded">
                      <input
                        id={`checkbox-item-${form.ai_form_id}`}
                        type="checkbox"
                        value={form.ai_form_id}
                        className="w-4 h-4"
                        checked={form.checked}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setCheckedForms((prevCheckedForms) =>
                            isChecked
                              ? [...prevCheckedForms, form.ai_form_id]
                              : prevCheckedForms.filter(
                                  (id) => id !== form.ai_form_id
                                )
                          );
                        }}
                      />
                      <label className="w-full ml-2 text-sm font-medium">
                        {form.form_name}
                      </label>
                    </div>
                  </li>
                ))
              ) : (
                <div className="animate-spin flex justify-center">
                  <ImSpinner size={30} />
                </div>
              )}
            </ul>
          </div>
        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Edit AI Forms
          </button>
        </div>
      </div>
    </form>
  );
};

export default Permissions;
