import { Helmet } from "react-helmet";
import ContactUs from "../ContactUs";
import Banner from "./Banner";
import Hero from "./Hero";
import Prompt from "./Prompt";
import Section1 from "./Section1";
import Efficiency from "./Efficiency";
import StandStandardization from "./StandStandardization";
import Validation from "./Validation";
import Seperator from "./Seperator";
import Footer from "../Footer";
import NavBar from "../NavBar/NavBar";
import FAQ from "./FAQ";

const HomePage = () => {
  return (
    <section className=" flex flex-col gap-2">
      <Helmet>
        <title>boxMind AIForm</title>
        <meta
          name="description"
          content="AI Forms revolutionizes workplace interactions by standardizing prompt engineering across teams. It is like having Google Forms but for Generative AI."
        />
      </Helmet>
      <NavBar />
      <div id="banner">
        <Banner />
      </div>
      <div id="Optimizing_Workplace">
        <Section1 />
      </div>
      <div id="build_better_prompts">
        <Hero />
      </div>
      <Seperator />
      <div id="standardization">
        <StandStandardization />
      </div>
      <div id="prompt">
        <Prompt />
      </div>
      <div id="validation">
        <Validation />
      </div>
      <div id="efficiency">
        <Efficiency />
      </div>
      <div id="benefits">
        <FAQ />
      </div>
      <div id="contact-us">
        <ContactUs />
      </div>
      <Footer />
    </section>
  );
};

export default HomePage;
