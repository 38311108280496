import { Outlet } from "react-router";
import NavBar from "../Navigations/NavBar";
import SideMenu from "../Navigations/SideMenu";
import { useState } from "react";
import { IoIosMenu } from "react-icons/io";

function Dashboard() {
  const [sideActive, setSideActive] = useState(false);

  function changeSideActive() {
    setSideActive(!sideActive);
  }

  return (
    <div className="h-screen lg:grid lg:grid-cols-7">
      {sideActive === true && (
        <div className="lg:hidden fixed right-5 top-6 text-[#4D30B5]">
          <button onClick={() => changeSideActive()}>
            <IoIosMenu size={25} />
          </button>
        </div>
      )}
      <div
        className={` lg:col-span-1 bg-[#20144C] h-full ${
          sideActive === false ? "max-lg:hidden" : ""
        }`}
      >
        <SideMenu changeSideActive={changeSideActive} />
      </div>
      <div className=" lg:col-span-6 h-screen flex flex-col w-full bg-[#F8FBFD]">
        <div className="h-[70px] ">
          <NavBar changeSideActive={changeSideActive} />
        </div>
        <div className=" lg:grid grid-cols-11 h-full overflow-y-scroll custom-scrollbar-chat p-4  bg-[#F8FBFD]">
          <div id="parent" className=" col-span-11">
            <div
              className="flex flex-col h-screen "
              style={{ maxHeight: "calc(100vh - 105px)" }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
