import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import axios from "axios";

function EditPrompt({
  handlePromptEditClick,
  closeEditPrompt,
  form_id,
  setRefresh,
  form_prompt,
  clientId,
}: any) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedJsonString, setEditedJsonString] = useState(form_prompt);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [loading, setLoading] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setEditedJsonString(form_prompt);
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedCustomForm = editedJsonString;
    try {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_EDIT_PROMPT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ai_form_id: form_id,
            user_id: clientId,
            prompt: updatedCustomForm,
          }),
        }
      );
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeEditPrompt();
      toast.success("Form Updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the form!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.error("An error occurred while updating the form:", error);
    } finally {
      setLoading(false);
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      closeEditPrompt();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md "
      onClick={handlePromptEditClick}
      role="button"
    >
      <div className="bg-white p-4 relative rounded shadow-2xl overflow-y-scroll max-h-[700px]">
        <button
          onClick={closeEditPrompt}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">Prompt</p>
        {isEditing ? (
          <form onSubmit={handleEditSubmit}>
            <textarea
              ref={textareaRef}
              value={editedJsonString}
              onChange={(e) => setEditedJsonString(e.target.value)}
              className="md:w-[750px] w-[350px] h-[500px] whitespace-break-spaces outline-none p-2"
            />

            <div className=" flex flex-row items-center mt-2 gap-4">
              <button
                onClick={handleEditToggle}
                className="bg-green-500 text-white p-2 "
              >
                {isEditing ? "Cancel Edit" : "Edit"}
              </button>
              <button
                disabled={loading}
                type="submit"
                className="bg-blue-500 text-white p-2"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="md:w-[750px] w-[350px] h-[500px] overflow-scroll whitespace-break-spaces">
              {editedJsonString}
            </div>
            <div className=" flex flex-row items-center justify-between">
              <button
                onClick={handleEditToggle}
                className="bg-green-500 text-white p-2 mt-2"
              >
                {isEditing ? "Cancel Edit" : "Edit"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EditPrompt;
