const Seperator = () => {
  return (
    <section className=" flex justify-center lg:py-[30px] py-[20px]">
      <div className=" border-t-[6px] border-[#1b374e] flex justify-center items-center w-[970px]">
        <div className="text-[25px] md:text-[60px] text-[#1b374e] pt-[12px] flex flex-col justify-center items-center">
          <p className=" uppercase font-bold">User Experience</p>
          <div className=" flex flex-row gap-4 items-center uppercase">
            <p>Our</p>
            <p className="text-[#ffc801]">Approach</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Seperator;
