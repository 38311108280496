import axios from "axios";

export async function fetchData(url: string, setData: Function) {
  try {
    const response = await axios.get(url);
    const data = response.data;
    setData(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
