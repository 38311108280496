import { useEffect, useState } from "react";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { fetchData } from "../../GlobalFunctions";
import { toast } from "react-toastify";
import { BsPlus } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { IoCreateOutline } from "react-icons/io5";

interface StyleItem {
  id: string;
  label: string;
  user_generated: boolean;
  value: string[];
}

type InputField = {
  label: string;
  type: string;
  value?: string | string[];
};

type FormValues = {
  fields: InputField[];
  id: string;
};

const DataList = ({ clientId }: any) => {
  const [dataList, setDataList] = useState<StyleItem[]>([]);
  const [showCreator, setShowCreator] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_DATA_LIST}${clientId}`,
      setDataList
    );
  }, [refresh]);

  const randomId = Math.random().toString(36).substring(7);
  const [loading, setLoading] = useState(false);
  const { register, control, handleSubmit, watch, reset, setValue } =
    useForm<FormValues>({
      defaultValues: {
        id: randomId,

        fields: [
          {
            label: "",
            type: "list",
          },
        ],
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const fieldTypes = watch("fields");

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_CREATE_DATA_LIST}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: clientId,
            id: randomId,
            fields: data.fields.map((field) => ({
              ...field,
              value: Array.isArray(field.value) ? field.value : [field.value],
            })),
          }),
        }
      );
      if (response.ok) {
        setRefresh((prevRefresh: any) => prevRefresh + 1);
        toast.success("Form data sent successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        reset();
      } else {
        toast.error("Failed to send form data.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    } finally {
      setLoading(false);
      reset();
      setShowCreator(false);
    }
  };

  const renderInput = (field: InputField, index: number) => {
    switch (field.type) {
      case "list":
        let valueArray: string[] = Array.isArray(field.value)
          ? field.value
          : [];
        return (
          <select
            {...register(`fields.${index}` as const)}
            className="border border-gray-300 p-2 rounded-md outline-none"
            value={valueArray}
            onChange={(e) => {
              const selectedOptions = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              field.value = selectedOptions;
            }}
            multiple
          >
            {valueArray.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type="text"
            {...register(`fields.${index}` as const)}
            className="border border-gray-300 p-2 rounded-md outline-none"
          />
        );
    }
  };

  const deleteItem = async (id: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_DELETE_LIST}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            user_id: clientId,
          }),
        }
      );
      if (response.ok) {
        setRefresh((prevRefresh: any) => prevRefresh + 1);
        toast.success("Data List deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        toast.error("Failed to delete data list.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error deleting data list:", error);
    }
  };

  return (
    <section className={`flex flex-col justify-center items-center gap-4`}>
      <Helmet>
        <title>boxMind Data List</title>
        <meta
          name="description"
          content="The Data List Dropdown tool in your Form builder simplifies creating
          dropdown menus. It organizes preset options for users to choose from,
          lets you customize them easily, and seamlessly fits into your setup for
          a better user experience."
        />
      </Helmet>
      <h1 className="text-2xl font-bold ">Data List</h1>
      <p className=" text-center md:w-[600px]">
        The Data List Dropdown tool in your Form builder simplifies creating
        dropdown menus. It organizes preset options for users to choose from,
        lets you customize them easily, and seamlessly fits into your setup for
        a better user experience.
      </p>
      <div className=" flex flex-col md:flex-row justify-center w-full gap-6">
        <div
          className=" flex flex-col gap-2 items-center overflow-scroll px-4"
          style={{ maxHeight: "calc(100vh - 250px)" }}
        >
          {dataList.map((item) => (
            <div key={item?.id} className=" flex flex-col gap-2 w-full">
              <label htmlFor={item?.label}>{item?.label}</label>
              <div className=" flex flex-row gap-2 items-center md:w-[400px]">
                <select
                  className="border text-white rounded bg-[#6347ca] p-3 w-full border-[#719bbd] outline-none"
                  id={item?.id}
                  name={item?.label}
                >
                  {item?.value?.map((option, optIndex) => (
                    <option
                      className=" bg-gray-400"
                      key={optIndex}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
                {item.user_generated === true && (
                  <button
                    onClick={() => deleteItem(item.id)}
                    className=" bg-red-500 text-white p-2 rounded-md outline-none"
                  >
                    <RiDeleteBin6Line />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div>
          {!showCreator && (
            <button
              className=" absolute right-5 top-[9%] md:top-[13%]"
              onClick={() => setShowCreator(!showCreator)}
            >
              <IoCreateOutline title="Create new data list" size={40} />
            </button>
          )}
          {showCreator && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="absolute right-0 top-0 h-full bg-white shadow-lg p-4 transition-transform transform translate-x-0"
            >
              <section className=" flex flex-col justify-center items-center gap-4">
                <p className="text-xl">Create Data List</p>
                <div className=" flex flex-col md:flex-row gap-4 justify-center">
                  <div className=" flex flex-col rounded-lg md:w-[335px] h-full">
                    <div
                      className=" grid grid-cols-1 gap-3 w-full overflow-y-auto"
                      style={{ maxHeight: "calc(100vh - 150px)" }}
                    >
                      {fields.map((field, index) => (
                        <div className=" flex flex-row">
                          <div className="bg-[#6347ca] px-1 rounded-l-lg" />
                          <div
                            key={field.id}
                            className="flex flex-col p-4 bg-white rounded-lg w-full"
                          >
                            <div className="flex flex-col gap-2 items-center">
                              <input
                                {...register(`fields.${index}.label` as const)}
                                placeholder="Field Name"
                                className="bg-gray-100 p-2 border-b border-[#20144c] outline-none w-full"
                              />
                              {["list"].includes(fieldTypes[index]?.type) && (
                                <input
                                  {...register(
                                    `fields.${index}.value` as const
                                  )}
                                  placeholder="Enter value comma separated"
                                  className="border border-gray-300 p-2 rounded-md outline-none w-full"
                                  onChange={(e) => {
                                    const listValues = e.target.value
                                      .split(",")
                                      .map((item) => item.trim());
                                    setValue(
                                      `fields.${index}.value`,
                                      listValues,
                                      {
                                        shouldValidate: true,
                                      }
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className=" flex flex-row items-center gap-2 justify-end border-t mt-3 pt-2">
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className=" bg-red-500 text-white p-2 rounded-md outline-none"
                              >
                                <RiDeleteBin6Line />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => append({ label: "", type: "list" })}
                      className=" bg-[#6347ca] text-white hover:bg-[#20144c] duration-300 rounded-full text-[35px]"
                    >
                      <BsPlus title="add a new field" />
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => setShowCreator(false)}
                  className="absolute top-2 right-3 text-2xl"
                >
                  <IoMdClose title="close" size={30} />
                </button>
              </section>
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-[#6347ca] text-white hover:bg-[#20144c] duration-300 px-4 py-2 rounded-md outline-none my-4"
                >
                  {loading ? "Sending..." : "Create Data List"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default DataList;
