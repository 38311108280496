import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

const FAQ = () => {
  const faq = [
    {
      id: 1,
      title: "Generate the Best Gen AI Response",
      text: "Ensures consistently high-quality outputs by tailoring prompts to meet specific AI criteria.",
    },
    {
      id: 2,
      title: "Standardize Output for LLM",
      text: "Creates uniformity in the responses received from language models across different teams and users.",
    },
    {
      id: 3,
      title: "Validate Input Before Sending to LLM Model",
      text: "Prevents errors and enhances efficiency by checking data completeness and accuracy before submission.",
    },
    {
      id: 4,
      title: "No Need for Advanced Prompt Engineers",
      text: "Simplifies the use of AI technology by automating prompt optimization, making it accessible to all employees regardless of their technical expertise.",
    },
    {
      id: 5,
      title: "Reduce Time and Effort",
      text: "Minimizes the time spent crafting and refining prompts, allowing employees to focus on decision-making and core activities.",
    },
    {
      id: 6,
      title: "Enhance Decision Making",
      text: "By providing more accurate and relevant AI-generated insights, it supports better business decisions.",
    },
    {
      id: 7,
      title: "Increase User Engagement",
      text: "User-friendly forms make interaction with AI more approachable and less intimidating for all users.",
    },
    {
      id: 8,
      title: "Improve Data Security",
      text: "Ensures that sensitive information is handled appropriately, with built-in validations to maintain data integrity.",
    },
    {
      id: 9,
      title: "Scale With Ease",
      text: "Facilitates scalability of AI solutions within the organization without the need for extensive training or additional resources.",
    },
    {
      id: 10,
      title: "Monitor Usage and Feedback",
      text: "Provides tools for tracking how forms are used and gathering feedback to continually refine the prompting process.",
    },
    {
      id: 11,
      title: "Data List",
      text: "The Data List Dropdown tool in your Form builder simplifies creating dropdown menus. It organizes preset options for users to choose from, lets you customize them easily, and seamlessly fits into your setup for a better user experience.",
    },
    {
      id: 12,
      title: "Role",
      text: "The Role Selector component allows users to define and select their roles within a conversation or context. It enables individuals to shape discussions, access relevant tools and data, and collaborate effectively based on their designated roles.",
    },
  ];

  const [selectedFaq, setSelectedFaq] = useState<string[]>([]);

  const handleCheckBoxChange = (id: string) => {
    setSelectedFaq((prevSelectedFaq) => {
      if (prevSelectedFaq.includes(id)) {
        return prevSelectedFaq.filter((faq_id) => faq_id !== id);
      } else {
        return [...prevSelectedFaq, id];
      }
    });
  };

  const handleSelect = (num: any) => {
    var div = document.getElementById(`expand-box-${num}`);
    if (div === null) {
    } else {
      if (div.className.includes("overflow-hidden")) {
        div.className =
          "transition-[max-height_opacity] duration-300 text-large-body max-md:text-medium-body max-sm:text-small-body max-w-[696px] opacity-60 max-h-[20rem]";
      } else {
        div.className =
          "transition-[max-height_opacity] duration-300 text-large-body max-md:text-medium-body max-sm:text-small-body max-w-[696px] opacity-60 overflow-hidden opacity-0 max-h-0";
      }
    }
  };

  return (
    <section className=" overflow-hidden text-black">
      <div className="container flex flex-col items-center justify-center mx-auto">
        <p className="text-[25px] md:text-[30px] text-[#1b374e] font-bold text-center py-4">
          Benefits or features
        </p>
        <div className="flex flex-col px-4 max-xl:min-w-full ">
          {faq.map((item, i) => {
            return (
              <div
                id=""
                key={i}
                className="flex flex-col pt-[15px] max-lg:items-center transition-[height] duration-300"
              >
                <div
                  className={`flex flex-col gap-[1rem] p-2 cursor-pointer rounded-lg ${
                    selectedFaq.includes(i.toString()) ? " bg-gray-100" : null
                  } duration-300`}
                  onClick={() => {
                    handleCheckBoxChange(i.toString());
                    handleSelect(i);
                  }}
                >
                  <div className="flex w-full justify-between items-center relative select-none">
                    <p className=" ">{item.title}</p>
                    <button aria-label="Expand FAQ answer">
                      <div
                        className={
                          "transition-[transform] duration-300 " +
                          (selectedFaq.includes(i.toString())
                            ? "rotate-45"
                            : " -rotate-90")
                        }
                      >
                        <AiOutlinePlus />
                      </div>
                    </button>
                  </div>
                  <div className="max-lg:flex max-lg:justify-center max-lg:items-center max-lg:max-w-[378px]">
                    <p
                      id={`expand-box-${i}`}
                      className={
                        "transition-[max-height_opacity] duration-300 max-w-[696px] opacity-60 " +
                        (selectedFaq.includes(i.toString())
                          ? "max-h-[20rem]"
                          : "overflow-hidden opacity-0 max-h-0")
                      }
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
