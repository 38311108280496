import React, { useEffect, useState } from "react";
import BoxMind from "../../Assets/boxMind_wide.png";
import { useLocation } from "react-router-dom";
import { GrYoutube } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";

const NavBar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav
      className={`w-full fixed z-50 transition-all ${
        pathname === "/"
          ? scrolling
            ? "bg-[#F8FBFD]"
            : "bg-transparent"
          : "bg-[#F8FBFD]"
      }`}
    >
      <div className=" mx-auto flex flex-row items-center justify-between lg:px-[120px] px-[30px] py-[16px]">
        <ScrollLink
          to={"banner"}
          smooth={true}
          duration={700}
          className=" cursor-pointer"
        >
          <img
            src={BoxMind}
            loading="lazy"
            alt="boxMind"
            width={150}
            height={58}
          />
        </ScrollLink>
        <div className={`lg:flex hidden flex-row gap-[48px] text-gray-400`}>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="banner"
            smooth={true}
            duration={700}
          >
            Home
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="standardization"
            smooth={true}
            duration={700}
          >
            Standardization
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="prompt"
            smooth={true}
            duration={700}
          >
            Simplify
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="validation"
            smooth={true}
            duration={700}
          >
            Validation
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="efficiency"
            smooth={true}
            duration={700}
          >
            Efficiency
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="benefits"
            smooth={true}
            duration={700}
          >
            Benefits
          </ScrollLink>
          <ScrollLink
            className={` hover:text-[#135991] duration-300 cursor-pointer`}
            to="contact-us"
            smooth={true}
            duration={700}
          >
            Contact Us
          </ScrollLink>
        </div>
        <div
          className={`lg:flex hidden flex-row items-center gap-4 text-gray-400`}
        >
          <a
            href="https://youtube.com/@boxMindai"
            target="blank"
            className={` hover:text-[red] duration-300`}
          >
            <GrYoutube size={25} />
          </a>
          <a
            href="https://www.linkedin.com/company/boxmindai"
            target="blank"
            className={` hover:text-[#135991] duration-300`}
          >
            <FaLinkedinIn size={20} />
          </a>
        </div>
        <div className="lg:hidden block">
          <button onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="lg:hidden block bg-gray-100 py-2">
          <ScrollLink
            onClick={toggleMenu}
            to="banner"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Home
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="standardization"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Standardization
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="prompt"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Simplify
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="validation"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Validation
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="efficiency"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Efficiency
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="benefits"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Benefits
          </ScrollLink>
          <ScrollLink
            onClick={toggleMenu}
            to="contact-us"
            smooth={true}
            duration={700}
            className="block py-2 px-4 hover:bg-gray-200"
          >
            Contact Us
          </ScrollLink>
          <div className=" py-2 flex flex-row justify-center items-center gap-4">
            <a
              href="https://youtube.com/@boxMindai"
              target="blank"
              className={` hover:text-[red] duration-300`}
            >
              <GrYoutube size={25} />
            </a>
            <a
              href="https://www.linkedin.com/company/boxmindai"
              target="blank"
              className={` hover:text-[#135991] duration-300 `}
            >
              <FaLinkedinIn size={20} />
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
