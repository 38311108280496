import "react-slideshow-image/dist/styles.css";
import Form from "../../Assets/AIForm.jpeg";

const Hero = () => {
  return (
    <section className="w-full flex justify-center items-center">
      <div className=" flex flex-col items-center gap-10">
        <div className=" flex flex-col items-center gap-4 px-4">
          <div className="flex flex-col gap-4 items-center justify-center pb-4">
            <p className="text-[25px] md:text-[30px] text-[#1b374e] font-bold text-center">
              AI Forms: Build Better Prompts, Build a Better Business
            </p>
            <p className="text-[#1b374e] md:text-[20px] font-bold text-center">
              Create unlimited AI Forms using our AI Form builder!
            </p>
          </div>
          <img
            src={Form}
            loading="lazy"
            alt="AIForm"
            className=" lg:h-[450px] rounded-lg"
          />
          <div className="text-[#1b374e] text-center lg:w-[900px]">
            <p>
              Crafting effective prompts for AI is key to improving interactions
              and boosting business performance. By focusing on creating better
              prompts, companies can enhance user experiences, optimize
              operations, and achieve greater success in the digital landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
