import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Headerbg1 from "../../Assets/headerbg1.jpg";
import Headerbg2 from "../../Assets/headerbg2.jpg";
import Headerbg3 from "../../Assets/headerbg3.jpg";
import Headerbg4 from "../../Assets/headerbg4.jpg";
import Headerbg5 from "../../Assets/headerbg5.jpg";
import Headerbg6 from "../../Assets/headerbg6.jpeg";
import Headerbg7 from "../../Assets/headerbg7.jpg";

import FormBackground1 from "../../Assets/form_background1.jpg";
import FormBackground2 from "../../Assets/form_background2.jpg";
import FormBackground3 from "../../Assets/form_background3.jpg";
import FormBackground4 from "../../Assets/form_background4.jpg";
import FormBackground5 from "../../Assets/form_background5.jpg";
import FormBackground6 from "../../Assets/form_background6.jpg";
import FormBackground7 from "../../Assets/form_background7.jpg";

import { useParams } from "react-router-dom";
import { AiOutlineCopy, AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import ReactDOM from "react-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function UsePrompt({ clientId }: any) {
  const captchaRef = useRef<any>(null);
  const formBackgroundImages = [
    FormBackground1,
    FormBackground2,
    FormBackground3,
    FormBackground4,
    FormBackground5,
    FormBackground6,
    FormBackground7,
  ];
  const headerBackgroundImages = [
    Headerbg1,
    Headerbg2,
    Headerbg3,
    Headerbg4,
    Headerbg5,
    Headerbg6,
    Headerbg7,
  ];

  const { ai_form_id } = useParams();
  const formBackgroundIndex = ai_form_id ? parseInt(ai_form_id, 10) : 0;
  const [promptData, setPromptData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [apiResponse, setApiResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [randomHeader, setRandomHeader] = useState<string | null>(null);
  const [randomBackground, setRandomBackground] = useState<string | null>(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API}${ai_form_id}/${clientId}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setPromptData(data);
        if (data.user_generated) {
          const randomIndex = Math.floor(
            Math.random() * headerBackgroundImages.length
          );
          setRandomHeader(headerBackgroundImages[randomIndex]);
          setRandomBackground(formBackgroundImages[randomIndex]);
        } else {
          setRandomHeader(headerBackgroundImages[formBackgroundIndex - 1]);
          setRandomBackground(formBackgroundImages[formBackgroundIndex - 1]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleInputChange = (key: string, value: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!recaptchaValue) {
      toast.error("Please complete the ReCAPTCHA verification.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }
    setLoading(true);

    var div = document.getElementById("copyToClipboard");
    if (div === null) {
      console.log(div);
    } else {
      ReactDOM.render(<AiOutlineCopy size={22} />, div);
    }

    const formValues = Object.entries(formData).map(([key, value]) => ({
      key,
      value,
    }));
    formValues.push({ key: "token", value: recaptchaValue });
    formValues.push({ key: "ai_form_id", value: ai_form_id });
    formValues.push({ key: "user_id", value: clientId });

    const formDataToSend = new FormData();
    formValues.forEach(({ key, value }: any) => {
      formDataToSend.append(key, value);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_USE_PROMPT}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setApiResponse(response.data);
    } catch (error) {
      toast.error("An error occurred while generating the answer", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    } finally {
      setLoading(false);
      setRecaptchaValue(null);
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }
  };

  const copyToClipboardHistory = () => {
    var div = document.getElementById("copyToClipboard");
    if (div === null) {
      console.log(div);
    } else {
      ReactDOM.render(<HiMiniClipboardDocumentCheck size={22} />, div);
    }
    let finalClip = apiResponse ? apiResponse : "";
    const unsecuredCopyToClipboard = (text: string) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(finalClip);
    } else {
      unsecuredCopyToClipboard(finalClip);
    }
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "dark",
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md">
      {randomBackground && (
        <img
          className=" fixed w-[2000px] h-screen"
          src={randomBackground}
          alt="background"
          loading="lazy"
        />
      )}
      <div
        className={`${
          apiResponse &&
          "md:grid md:grid-cols-2 gap-5 max-md:h-screen max-md:p-4"
        } duration-300  `}
      >
        <div className=" lg:max-w-[400px] max-w-[350px] bg-white rounded-lg  relative  shadow-2xl  max-h-[650px] ">
          {randomHeader && (
            <img
              className=" lg:max-w-[400px] max-w-[350px] rounded-t-lg"
              src={randomHeader}
              alt="header"
              loading="lazy"
            />
          )}
          <p className="text-[25px] font-semibold text-[#4D30B5] text-center content-center mt-2">
            {promptData?.form_name}
          </p>
          <form onSubmit={handleSubmit} className="h-[90%]">
            <div className="p-4 flex flex-col gap-4 overflow-y-scroll max-h-[420px] ">
              {promptData?.custom_form?.fields?.map(
                (field: any, index: number) => (
                  <div key={index} className="flex flex-col">
                    {field?.type === "text" ? (
                      <>
                        <input
                          placeholder={field?.label}
                          maxLength={field?.validation?.maxLength}
                          required={field?.validation?.required}
                          type="text"
                          value={formData[field?.label] || ""}
                          onChange={(e) =>
                            handleInputChange(field?.label, e.target.value)
                          }
                          className="text-[20px] p-2 border-b border-[#4D30B5] outline-none"
                        />
                      </>
                    ) : field?.type === "textarea" ? (
                      <textarea
                        maxLength={field?.validation?.maxLength}
                        placeholder={field?.label}
                        required={field?.validation?.required}
                        value={formData[field?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field?.label, e.target.value)
                        }
                        className="text-[20px] p-2 border-b border-[#4D30B5] outline-none"
                      />
                    ) : field?.type === "list" ? (
                      <select
                        required={field?.validation?.required}
                        value={formData[field?.label] || ""}
                        onChange={(e) =>
                          handleInputChange(field?.label, e.target.value)
                        }
                        className="text-[20px] p-2 pl-0 border-b border-[#4D30B5] outline-none hover:bg-[#EDEAF8] cursor-pointer duration-300"
                      >
                        <option value="">Select a {field.label}</option>
                        {field.value.map(
                          (option: string, optionIndex: number) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    ) : null}
                  </div>
                )
              )}
              <div className=" flex justify-center">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6Lf0ASwpAAAAAKw-ddwbvo9q7b5fVD33NrDSTAQI"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <div className="mt-10 mb-3 flex justify-center">
                <button
                  disabled={loading}
                  type="submit"
                  className={`${
                    loading ? "bg-[#1b1436]" : "bg-[#4D30B5]"
                  }  hover:bg-[#1b1436] text-white py-3 px-5 rounded `}
                >
                  {loading ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : promptData?.custom_form?.key_submit ? (
                    promptData?.custom_form?.key_submit
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        {apiResponse && (
          <div className="md:mt-0 mt-5 lg:max-w-[400px] max-w-[350px] bg-white rounded-lg relative shadow-2xl max-h-[650px]">
            <div className="bg-white p-4 rounded shadow-2xl relative h-full ">
              <button
                type="button"
                onClick={() => copyToClipboardHistory()}
                id="copyToClipboard"
                className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-4 right-2"
              >
                <AiOutlineCopy size={22} />
              </button>

              <p className="text-[30px] text-center content-center text-[#4D30B5]">
                Result:
              </p>
              <div className=" mt-2 lg:max-w-[400px] max-w-[350px] overflow-scroll max-h-[550px]  whitespace-break-spaces text-justify">
                {loading ? (
                  <div className="text-black rounded flex  justify-center h-[50px] mt-40 ">
                    <AiOutlineLoading3Quarters
                      size={30}
                      className=" animate-spin text-black"
                    />
                  </div>
                ) : (
                  apiResponse
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsePrompt;
